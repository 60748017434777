import React from 'react';
import {arrayOf, bool, oneOf, shape, string, number} from 'prop-types';
import LinkUrl from '../LinkUrl/LinkUrl';
import emmezetaPlaceholder from '../../assets/img/emmezeta-placeholder.png';
import readConfig from '../../utils/readClientConfiguration';
import productionConfiguration from '../../../config/default.json';

const cdnBaseUrl = readConfig(productionConfiguration, 'cdn');

export function getImageUrl(rawSource) {
    if (rawSource === 'false' || rawSource === false) {
        return emmezetaPlaceholder;
    }

    if (rawSource.includes('https://')) {
        return rawSource;
    }

    if (Array.from(rawSource)[0] == '/') {
        return `${cdnBaseUrl}${rawSource}`;
    }

    return `${cdnBaseUrl}/${rawSource}`;
}

const Image = props => {
    const {
        desktopImage,
        mobileImage,
        alt,
        title,
        linkUrl,
        canonicalUrl,
        openInNewTab,
        caption,
        textAlign,
        border,
        borderColor,
        borderWidth,
        borderRadius,
        display,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        cssClasses = [],
        disableLazy,
    } = props;

    const figureStyles = {
        textAlign,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
    };
    const imageStyles = {
        border,
        borderColor,
        borderWidth,
        borderRadius,
        display,
    };

    // Don't render anything if there is no image to be rendered
    if (!desktopImage && !mobileImage) {
        return null;
    }

    const SourceFragment =
        mobileImage && mobileImage.url ? (
            <img
                className="pagebuilder-mobile-only"
                src={getImageUrl(mobileImage.url)}
                title={title}
                alt={alt}
                style={imageStyles}
                loading={disableLazy ? undefined : 'lazy'}
            />
        ) : (
            ''
        );
    const PictureFragment = (
        <>
            {SourceFragment}
            <img
                className={mobileImage && 'pagebuilder-mobile-hidden'}
                src={getImageUrl(desktopImage.url)}
                title={title}
                alt={alt}
                style={imageStyles}
                loading={disableLazy ? undefined : 'lazy'}
            />

            {caption ? <figcaption>{caption}</figcaption> : ''}
        </>
    );

    if (linkUrl) {
        return (
            <figure style={figureStyles} className={cssClasses && cssClasses.join(' ')}>
                <LinkUrl linkUrl={linkUrl} canonical_url={canonicalUrl} {...(openInNewTab ? {target: '_blank'} : '')}>
                    {PictureFragment}
                </LinkUrl>
            </figure>
        );
    }

    return (
        <figure style={figureStyles} className={cssClasses && cssClasses.join(' ')}>
            {PictureFragment}
        </figure>
    );
};

/**
 * Props for {@link Image}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the Image
 * @property {String} classes.img CSS classes for the img element
 * @property {String} desktopImage URL src of the desktop image
 * @property {String} mobileImage URL src of the mobile image
 * @property {String} alt Alternate text
 * @property {String} title Title of the image
 * @property {String} link URL to redirect to
 * @property {String} linkType Type of link
 * @property {bool} openInNewTab Flag to indicate if link should be opened in a new tab
 * @property {String} caption Caption for the image
 * @property {String} textAlign Alignment of the divider within the parent container
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 */
Image.propTypes = {
    classes: shape({
        img: string,
    }),
    desktopImage: shape({
        name: string,
        size: number,
        type: string,
        url: string,
    }),
    mobileImage: shape({
        name: string,
        size: number,
        type: string,
        url: string,
    }),
    alt: string,
    title: string,
    linkUrl: shape({
        default: string,
        setting: bool,
        type: oneOf(['default', 'category', 'product', 'page']),
    }),
    openInNewTab: bool,
    caption: string,
    textAlign: string,
    border: string,
    borderColor: string,
    borderWidth: string,
    borderRadius: string,
    display: string,
    marginTop: string,
    marginRight: string,
    marginBottom: string,
    marginLeft: string,
    paddingTop: string,
    paddingRight: string,
    paddingBottom: string,
    cssClasses: arrayOf(string),
};

export default Image;
