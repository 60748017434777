import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { T } from '@deity-io/falcon-i18n';

import './ProductDescription.scss';

const ProductDescription = ({ tabId, setTab, product }) => {
  const { storefront_attributes: { dimensionAttributes, packagingAttributes, otherAttributes } = {}, description } =
    product || {};

  useEffect(() => {
    if (document.getElementById("prilozi")){
      const x = document.getElementById("prilozi");
      x.addEventListener("click", () => setTab(4));
    }
  }, [])

  return (
    <div className="product-description">
      <div className="product-description__specs">
        <ul className="product-description__dimensions" id="description">
          <li className="product-description__header">
            <T id="product.dimensions" />
          </li>
          {dimensionAttributes &&
            dimensionAttributes.map(({ label, value }) => (
              <li key={label}>
                {label}: <span dangerouslySetInnerHTML={{__html: value}}/>
              </li>
            ))}
        </ul>
        <ul className="product-description__info">
          <li className="product-description__header">
            <T id="product.additionalInfo" />
          </li>
          {otherAttributes &&
            otherAttributes.map(({ label, value }) => (
              <li key={label}>
                {label}: <span dangerouslySetInnerHTML={{__html: value}}/>
              </li>
            ))}
        </ul>
        <ul className="product-description__packing">
          <li className="product-description__header">
            <T id="product.packaging" />
          </li>
          {packagingAttributes &&
            packagingAttributes.map(({ label, value }) => (
              <li key={label}>
                {label}: <span dangerouslySetInnerHTML={{__html: value}}/>
              </li>
            ))}
        </ul>
      </div>
      <div className="product-description__full">
        <div className="product-description__header">
          <T id="product.description" />
        </div>
        {description && <div dangerouslySetInnerHTML={{ __html: description.html }} />}
      </div>
    </div>
  );
};

ProductDescription.propTypes = {
  product: PropTypes.object,
};

export default ProductDescription;
