import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {string} from 'prop-types';
import parse, {domToReact} from 'html-react-parser';
import loadable from '@loadable/component';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import {Loader} from '@deity-io/falcon-ecommerce-uikit';
import RootComponent from '../../renderComponents/RootComponent/RootComponent';
import {PAGE_HIERARCHY} from './queryPageHierarchy';
import RepairShops from './RepairShops';
import PageHierarchy from './PageHierarchy';
import './PageRenderer.scss';
import productionConfiguration from '../../../config/default.json';
import readConfig from '../../utils/readClientConfiguration';
import SeoBlock from "../SeoBlock";

const pointingToSelf = link => {
  const base = readConfig(productionConfiguration, 'base');

  // Check if the url contains the specific domain we're trying to point to
  return (link.hostname === `www.${base}`) || (link.hostname === base);
}

const FormBuilderAjax = loadable(() => import('./formBuilderAjax'), { ssr: false });
const PitanjaOProizvodu = loadable(() => import('./PageSpecific/PitanjaProizvod'), { ssr: false });

export const returnValidJson = str => {
  try {
    const json = JSON.parse(str);
    return typeof json === 'object' && json;
  } catch (e) {
    return false;
  }
};

const HTMLRenderer = ({ routeData, dynamicFields, setDynamicFields }) => {
  const { data, loading } = useQuery(PAGE_HIERARCHY, {
    variables: {
      identifier: routeData.identifier,
    },
  });

  const pageContent = routeData.hydrated_content.replace(/#html-body/g, 'body');
  function replaceHTMLNodesWithReact(domNode) {
    // null return means don't replace anything - keep the original
    const { name, attribs, children } = domNode;
    try {
      if (attribs.class && attribs.class === 'repair-shops') {
        return <RepairShops />;
      }
      // Rewrite the links so that they work correctly on the PWA side
      if (name === 'a' && attribs.href) {
        const rawLink = attribs.href;

        try {
          const link = new URL(rawLink);
          if (pointingToSelf(link)) {
            const processedLink = link.pathname.endsWith('/') ? link.pathname.slice(0, -1) : link.pathname;
            return <Link to={processedLink}>{domToReact(children)}</Link>;
          }
        } catch (e) {
          // Rewrite the links that are pointing in a relative way (implicitly us)
          const processedLink = rawLink.endsWith('/') ? rawLink.slice(0, -1) : rawLink;
          return <Link to={processedLink}>{domToReact(children)}</Link>;
        }

        if (attribs.href.includes('/maps/place/')) {
          return null;
        }
        if (attribs.href.includes('tel:')) {
          return null;
        }
        if (attribs.href.includes('@')) {
          return null;
        }
        if (attribs.href.includes('rezervacije')) {
          return null;
        }
        if (attribs.href.includes('cdn2')) {
          return null;
        }
      }

      // Enable dynamic form functionality
      if (name === 'ul' && attribs.class === 'formbuilder_multi_row_list') {
        return <ul className="formbuilder_multi_row_list">{dynamicFields.map(node => parse(node))}</ul>;
      }

      // Delete value so that we can change it later
      if (name === 'input' && attribs.type && attribs.type === 'hidden') {
        // Ignore things that actually have a value
        if (attribs.value) return null;
        delete domNode.attribs.value;
        return domToReact(domNode);
      }

      // Remove script tags
      if (name === 'script' && !attribs.id === "whitelisted-tag") {
        return <></>;
      }
    } catch (e) {
      // TODO: check error handling
      // Throw error and don't replace
      // console.error("Error while trying to replace HTML nodes with react", e)
      return null;
    }
    return null;
  }

  const hideForLayout=[
      '1column',
      'cms-full-width'
  ]

  let hidePageHierarchy = hideForLayout.includes(routeData.page_layout);

  if (data && data.pageHierarchy && Array.isArray(data.pageHierarchy) && data.pageHierarchy.length === 0) {
    hidePageHierarchy = true;
  }
  const classes = clsx( {
    'content__no-hierarchy': hidePageHierarchy,
    'content': !hidePageHierarchy,
  });
  return (
      <>
        {!hidePageHierarchy && <h1 className="main__title">{routeData.title}</h1>}

        {loading && (
            <div style={{width: '100%', height: '100vh'}}>
              <Loader/>
            </div>
        )}

      {!loading && (
        <div className="main">
          {!hidePageHierarchy && <PageHierarchy data={data} />}

          {pageContent && (
            <div
              className={classes}
            >
              <div data-page={routeData.identifier}>
                <FormBuilderAjax setDynamicFields={setDynamicFields} />
                <PitanjaOProizvodu />
                {parse(pageContent, { replace: replaceHTMLNodesWithReact })}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const JSONRenderer = React.memo(({ routeData }) => {
  const [isPageHierarchyActive, setIsPageHierarchyActive] = useState(false);
  const [startQuery, { data, loading }] = useLazyQuery(PAGE_HIERARCHY);

  useEffect(() => {
    if (routeData.url_key.includes('tablica')) {
      setIsPageHierarchyActive(true);
      startQuery({ variables: { identifier: routeData.identifier } });
    }
  }, [routeData]);

  if (!routeData.content_json) {
    return <h1>No content for page (try saving the page in admin and refreshing the site)</h1>;
  }

  const json = JSON.parse(routeData.content_json);

  return (
    <div className="cms-page-view" style={{ marginTop: 20 }}>
      <Helmet title={routeData.title} />
      {isPageHierarchyActive ? (
        <div className="main">
          {loading ? (
            <div style={{ width: '30%', height: '100vh' }}>
              <Loader />
            </div>
          ) : (
            <PageHierarchy data={data} />
          )}
          <div className="content">
            <RootComponent children={json} />
          </div>
        </div>
      ) : (
        <RootComponent children={json} />
      )}
    </div>
  );
});

const Page = ({ routeData }) => {
  const [dynamicFields, setDynamicFields] = useState([]);
  const openGraph = routeData.open_graph;

  const useJSONRenderer =
    routeData.url_key === 'home' ||
    routeData.url_key === 'emma-home' ||
    routeData.url_key === 'opremanje-apartmana' ||
    routeData.url_key === 'ljetna-zabava' ||
    routeData.url_key === 'tablica' ||
    routeData.url_key === 'tablica-webshop' ||
    routeData.url_key === 'kontakt-i-usluge/nacini-placanja-i-krediti/tablica' ||
    routeData.url_key === 'kontakt-i-usluge/nacini-placanja-i-krediti/tablica-webshop';

  return (
    <>
      <SeoBlock
          routeData={routeData}/>

      {useJSONRenderer ? (
        <JSONRenderer routeData={routeData} />
      ) : (
        <HTMLRenderer routeData={routeData} dynamicFields={dynamicFields} setDynamicFields={setDynamicFields} />
      )}
    </>
  );
};

Page.propTypes = {
  path: string,
};

export default Page;
