import React, {useEffect, Fragment, useState, useContext} from "react";
import {useQuery} from "@apollo/react-hooks";
import {REPAIR_SHOPS, REPAIR_SHOPS_DROPDOWN} from "./queryPageHierarchy";
import {Link} from "react-router-dom";
import {I18nContext, T} from '@deity-io/falcon-i18n';
import Loader from "../UI/Loader/Loader";

const RepairShops = () => {
    const { t } = useContext(I18nContext);
    const [city, setCity] = useState();
    const [category, setCategory] = useState();
    const [manufacturer, setManufacturer] = useState();
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])

    useEffect(() => {
        if(window){
            const path = window.location.href;
            const pageNumber = (path.slice(-1));
            if (pageNumber !== "a"){
                setPage(Number(pageNumber))
            }
        }
    })

    useEffect(() => {
        if(data && data.repairShops){
            for (let i = 0; i < data.repairShops.pages; i++){
                setPages(prevState => prevState.concat(i+1))
            };
        }
    }, [])

    const {data, loading} = useQuery(REPAIR_SHOPS, {
        variables:{
            page,
            city,
            category,
            manufacturer,
        },
        fetchPolicy: "cache-first"
    })
    const {data: dropdown} = useQuery(REPAIR_SHOPS_DROPDOWN, {
        fetchPolicy: "cache-first"
    })

    const filterCitiesHandler = (event) => {
        setCity(event.target.value)
        if(event.target.value === ""){
            setCity()
        }
    }
    const filterManufacturerHandler = (event) => {
        setManufacturer(event.target.value);
        if(event.target.value === ""){
            setManufacturer()
        }
    }
    const filterCategoriesHandler = (event) => {
        setCategory(event.target.value);
        if(event.target.value === ""){
            setCategory()
        }
    }
    return <Fragment>
        <div className="repair-shops">
            <form id="find_services" name="repairShopForm">
                <fieldset>
                    <ul className="repair-shops-filter">
                        <li className="filter-item">
                            <p><label htmlFor=""><T id="address.city" /></label></p>
                            <p>
                                <select name="city" id="city" onChange={filterCitiesHandler}>
                                    <option value="">{t("repairShops.anyCity")}</option>
                                    {dropdown && dropdown.repairShopsDropdown &&
                                        dropdown.repairShopsDropdown.cities.map(({key, value}) => (
                                            <option value={key}>{value}</option>
                                        ))}
                                </select>
                            </p>
                        </li>
                        <li className="filter-item">
                            <p><label htmlFor=""><T id="repairShops.manufacturer" /></label></p>
                            <p>
                                <select name="manufacturer" id="manufacturer" onChange={filterManufacturerHandler}>
                                    <option value="">{t("repairShops.allManufacturer")}</option>
                                    {dropdown && dropdown.repairShopsDropdown &&
                                        dropdown.repairShopsDropdown.categories.map(({key, value}) => (
                                            <option value={key}>{value}</option>
                                        ))}
                                </select>
                            </p>
                        </li>
                        <li className="filter-item">
                            <p><label htmlFor=""><T id="blog.category" /></label></p>
                            <p>
                                <select name="category" id="category" onChange={filterCategoriesHandler}>
                                    <option value="">{t("repairShops.allCategories")}</option>
                                    {dropdown && dropdown.repairShopsDropdown &&
                                        dropdown.repairShopsDropdown.manufacturers.map(({key, value}) => (
                                            <option value={key}>{value}</option>
                                        ))}
                                </select>
                            </p>
                        </li>
                    </ul>
                </fieldset>
            </form>
            {loading && <Loader />}
            {!loading && <div className="repair-shops-list">
                {data && data.repairShops &&
                    data.repairShops.shops.map(({name, address, phone, city_id}) => {
                        const city_name = dropdown &&
                            dropdown.repairShopsDropdown &&
                            dropdown.repairShopsDropdown.cities.find(({key}) => Number(key) === city_id);
                            return <div className="shop-list-item">
                                <h3 className="shop-name">{name}</h3>
                                <div className="shop-info">
                                    <p className="shop-address">{address}</p>
                                    <p className="shop-city">{city_name && city_name.value}</p>
                                    <p className="shop-phone">{phone}</p>
                                </div>
                            </div>
                    })}
            </div>}

            <div className="repair-shops-pager">
                {page > 1 && <div className="prev"><Link to={`/kontakt-i-usluge/jamstva-servisi-cesta-pitanja/popis-servisa?page=${page - 1}`}>
                    <p><T id="category.pagerPrevious" /></p></Link></div>}

                <ul className="pages">
                    {pages.map(i => (
                        <li>
                            {i === page ? <span>{i}</span> : <Link to={`/kontakt-i-usluge/jamstva-servisi-cesta-pitanja/popis-servisa?page=${i}`}>{i}</Link>}
                        </li>
                    ))
                    }
                </ul>

                {page < pages.slice(-1) && <div className="next"><Link
                    to={`/kontakt-i-usluge/jamstva-servisi-cesta-pitanja/popis-servisa?page=${page + 1}`}>
                    <p><T id="category.pagerNext" /></p></Link></div>}
            </div>

        </div>
    </Fragment>
}

export default RepairShops;
