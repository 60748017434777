import React, { useContext } from 'react';
import { arrayOf, oneOf, string, bool } from 'prop-types';
import LinkUrl from '../LinkUrl/LinkUrl';
import {I18nContext, T} from '@deity-io/falcon-i18n';

const ButtonItem = props => {

  const { t } = useContext(I18nContext);
  let {
    buttonType,
    name,
    buttonText: text,
    textAlign,
    border,
    buttonLink,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    cannonicalUrl,
    cssClasses = [],
  } = props;

  const dynamicInnerStyles = {
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };

  const justifyMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };
  if (textAlign) {
    dynamicInnerStyles.justifyContent = justifyMap[textAlign] || 'center';
    dynamicInnerStyles.textAlign = textAlign;
  }

  const buttonProps = {
    className: buttonType,
    style: dynamicInnerStyles,
    type: 'button',
    name,
  };

  const onClick = () => {
    if (cssClasses.includes('about-our-company-row-button')) {
      const button = document.querySelector('.about-our-company-row-button');
      const content = document.querySelector('.about-our-company-row-content');
      if (button === null) {
        const lessButton = document.querySelector('.about-our-company-row-less-button');
        content.classList.remove('active');
        lessButton.classList.remove('about-our-company-row-less-button');
        lessButton.classList.add('about-our-company-row-button');
        lessButton.childNodes[0].textContent = t('more');
      } else {
        content.classList.add('active');
        button.classList.add('about-our-company-row-less-button');
        button.classList.remove('about-our-company-row-button');
        button.childNodes[0].textContent = t('close');
      }
    }
  };

  return (
    <div className={`${cssClasses.length ? cssClasses.join(' ') : undefined}`} onClick={onClick} style={{ ...dynamicInnerStyles, padding: "10px 10px 20px" }}>
      <LinkUrl {...buttonProps} linkUrl={buttonLink} canonical_url={cannonicalUrl}><T id="more" /></LinkUrl>
    </div>
  );
};

/**
 * Props for {@link ButtonItem}
 *
 * @typedef props
 *
 * @property {String} buttonType Sets button type option
 * @property {String} link Url to the page opened when button clicked
 * @property {String} linkType Type of the linked page
 * @property {String} openInNewTab Toggles the option to open linked page in the new tab
 * @property {String} text Button text
 * @property {String} textAlign Button text align
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 */
ButtonItem.propTypes = {
  buttonType: oneOf(['primary', 'secondary', 'link']),
  link: string,
  href: string,
  linkType: oneOf(['default', 'category', 'product', 'page']),
  openInNewTab: bool,
  text: string,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string),
};

export default ButtonItem;
