import React, {Fragment, useState} from 'react';
import {T} from '@deity-io/falcon-i18n';
import clsx from 'clsx';
import {NavLink} from 'react-router-dom';

const MenuListItem = ({children}) => <li className="hierarchy__menu-item__parent">{children}</li>;

const PageHierarchy = ({data}) => {
  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = () => setShowMenu(prevState => !prevState);
  return (
    <Fragment>
      {data && data.pageHierarchy && (
        <div className="hierarchy">
          <label htmlFor="user-menu" className="user-dropdown" onClick={toggleMenu}>
            <T id="searchResult.contact" />
          </label>

          <label htmlFor="user-menu" className="hierarchy__menu-title">
            <T id="searchResult.contact" />
          </label>

          <ul
            className={clsx('hierarchy__menu', {
              hierarchy__menu_hidden: showMenu !== false,
            })}
          >
            {data &&
                data.pageHierarchy
                && Array.isArray(data.pageHierarchy)
                && data.pageHierarchy.length >0
                && data.pageHierarchy[0].children.map(item => (
                    <MenuListItem key={item.request_url}>
                      <NavLink
                          className="hierarchy__menu-item"
                          activeClassName="hierarchy__menu-item_active"
                          to={`/${item.request_url}`}
                      >
                        {item.label ? item.label : item.page_title}
                      </NavLink>
                      {item.children.map(child => (
                          <ul className="hierarchy__menu" key={child.request_url}>
                      <li className="hierarchy__menu-item__child">
                        <NavLink activeClassName="hierarchy__menu-item_active" to={`/${child.request_url}`}>
                          {child.label ? child.label : child.page_title}
                        </NavLink>
                      </li>
                    </ul>
                  ))}
                </MenuListItem>
              ))}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default PageHierarchy;
