import gql from "graphql-tag";

export const PAGE_HIERARCHY = gql`
    query pageHierarchy($identifier: String!){
        pageHierarchy(identifier: $identifier) {
            children {
                label
                page_title
                request_url
                children{
                    label
                    page_title
                    request_url
                }
            }
        }
    }
`

export const REPAIR_SHOPS = gql`
    query repairShops(
        $page: Int
        $pageSize: Int
        $city: String
        $category: String
        $manufacturer: String
    ) {
        repairShops(
            page: $page
            pageSize: $pageSize
            city: $city
            category: $category
            manufacturer: $manufacturer
        ) {
            pages
            shops {
                id
                address
                city_id
                email
                name
                phone
                store_id
            }
        }
    }
`

export const REPAIR_SHOPS_DROPDOWN = gql`
    query repairShopsDropdown{
        repairShopsDropdown{
            categories{
                name
                key
                value
            }
            cities{
                key
                name
                value
            }
            manufacturers{
                key
                name
                value
            }
        }
    }
`

export const QUERY_BACKEND_URL = gql`
    query storeConfig {
        storeConfig {
            base_url
        }
    }
`;