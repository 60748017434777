import React from 'react';
import { bool, oneOf, shape, string } from 'prop-types';
import LinkUrl from '../LinkUrl/LinkUrl';
import './style.scss';

const extractImg = data => {
  let matches ;
  matches = data.match(/url=(.*?)}}/);
  if (matches && matches[1]){
    // Parsing is slow, replacing is fast - only offending character is " anyways
    const match = matches[1].replace(/&quot;/g, "");
    return `https://s3.emmezeta.hr/media/${match}`;
  }
  matches = data.match(/src="(.*?)"/);
  if (matches && matches[1]){
    // Parsing is slow, replacing is fast - only offending character is " anyways
    const match = matches[1].replace(/&quot;/g, "");
    return `${match}`;
  }
  return '';
};

const Slide = ({ data, disableLazy }) => {

  const { data: { content, link_url: linkUrl, canonical_url } = {} } = data || {};
  return (
    <div className="pagebuilder-slide-wrapper">
      <LinkUrl linkUrl={linkUrl} canonical_url={canonical_url}>
        <img
          loading={disableLazy ? undefined : 'lazy'}
          src={extractImg(content)}
          alt="slide"
        />
      </LinkUrl>
    </div>
  );
};

Slide.propTypes = {
  content: string,
  link_url: shape({
    default: string,
    setting: bool,
    type: oneOf(['default', 'category', 'product', 'page']),
  }),
};

export default Slide;
