import React from 'react';
import clsx from 'clsx';
import { Tabs as TabWrapper, TabList, Tab as TabHeader, TabPanel } from 'react-tabs';
import { arrayOf, number, oneOf, shape, string } from 'prop-types';
import { mergeClasses } from '../../utils/mergeClasses';
import RootComponent from '../RootComponent/RootComponent';
import defaultClasses from './style.scss';

const upperCaseString = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

const Tabs = props => {
  const classes = mergeClasses(defaultClasses, props.cssClasses);
  const {
    navigationAlignment: tabNavigationAlignment = 'right',
    minHeight,
    defaultIndex = 0,
    DefaultActiveOptions: headers = [],
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    cssClasses = [],
    children,
  } = props;

  if (!headers.length) {
    return null;
  }

  const wrapperStyles = {
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };

  const contentStyles = {
    minHeight,
    textAlign,
  };

  const tabWrapperProps = {
    defaultIndex,
  };

  if (border) {
    wrapperStyles['--tabs-border'] = border;
    wrapperStyles['--tabs-border-color'] = borderColor;
  }
  if (borderWidth) {
    wrapperStyles['--tabs-border-width'] = borderWidth;
  }
  if (borderRadius) {
    wrapperStyles['--tabs-border-radius'] = borderRadius;
  }

  const tabHeaders = headers.map(header => (
    <TabHeader className={clsx(classes.header, 'tab-nav__item')} key={header.value}>
      {header.label}
    </TabHeader>
  ));

  const tabPanels = children.props.children.children.map(child => {
    return (
      <TabPanel className={clsx(classes.panel, 'tab-list__content-item')} selectedClassName={classes.panelSelected}>
        <RootComponent children={child} />
      </TabPanel>
    );
  });

  const navigationClass = classes[`navigation${upperCaseString(tabNavigationAlignment)}`];

  const contentClass = classes[`content${upperCaseString(tabNavigationAlignment)}`];

  return (
    <TabWrapper
      style={wrapperStyles}
      className={clsx(classes.root, cssClasses, 'tabs-list')}
      disabledTabClassName={classes.disabled}
      selectedTabClassName={classes.selected}
      {...tabWrapperProps}
    >
      <div className="tabs-list__nav">
        <TabList className={clsx(navigationClass, 'tab-nav')}>{tabHeaders}</TabList>
      </div>
      <div className={clsx(contentClass, 'tabs-list__content')} style={contentStyles}>
        {tabPanels}
      </div>
    </TabWrapper>
  );
};

/**
 * Props for {@link Tabs}
 * @typedef props
 *
 * @property {object} classes An object containing the class names for the Tabs
 * @property {string} classes.header Class names for the tab header
 * @property {string} classes.panelSelected Class names for the selected tab panel
 * @property {string} classes.panel Class names for the tab panel
 * @property {string} classes.contentLeft Class names for the tab content
 * @property {string} classes.contentCenter Class names for the tab content
 * @property {string} classes.contentRight Class names for the tab content
 * @property {string} classes.navigationLeft Class names for the tab navigation
 * @property {string} classes.navigationCenter Class names for the tab navigation
 * @property {String} classes.navigationRight Class names for the tab navigation
 * @property {String} classes.navigationGradientLeft Class names for the tab navigation gradient when scrolling
 * @property {String} classes.navigationGradientRight Class names for the tab navigation gradient when scrolling
 * @property {String} classes.navigationGradientBoth Class names for the tab navigation gradient when scrolling
 * @property {String} classes.disabled Class names for the disabled tabs
 * @property {String} classes.selected Class names for the selected tab
 * @property {String} classes.item Class names for the tab item
 * @property {String} tabNavigationAlignment Navigation alignment for tabs
 * @property {String} minHeight Minimum height of the tabs
 * @property {Number} defaultIndex Index of the tab to display by default
 * @property {Array} headers Array of tab headers
 * @property {String} textAlign Alignment of the Tabs within the parent container
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 */
Tabs.propTypes = {
  classes: shape({
    header: string,
    panelSelected: string,
    panel: string,
    contentLeft: string,
    contentCenter: string,
    contentRight: string,
    navigationLeft: string,
    navigationCenter: string,
    navigationRight: string,
    navigationGradientLeft: string,
    navigationGradientRight: string,
    navigationGradientBoth: string,
    disabled: string,
    selected: string,
    item: string,
  }),
  tabNavigationAlignment: oneOf(['left', 'center', 'right']),
  minHeight: string,
  defaultIndex: number,
  headers: arrayOf(string),
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string),
};

export default Tabs;
