import React from 'react';
import {T} from '@deity-io/falcon-i18n';

import './ProductDeclaration.scss';

const ProductDeclaration = ({product}) => {
    const {
      storefront_attributes: { declarationAttributes },
    } = product;

    if(!product.storefront_attributes || !declarationAttributes.length){
        return (<ul className="product-declaration">
            <div className="product-declaration__no-declaration">
                <T id="product.noDeclaration" />
            </div>
        </ul>)
    }

    return (
      <ul className="product-declaration">
        {declarationAttributes.map(({ label, value }) => (
          <li key={label}>
            <span className="product-declaration__label">{label}: </span>
            {value}
          </li>
        ))}
      </ul>
    );
};

export default ProductDeclaration;
