import React, { Children } from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import SlickSlider from 'react-slick';
import { mergeClasses } from '../../utils/mergeClasses';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slide from '../Slide/Slide';
import defaultClasses from './style.scss';
// import { jarallax } from 'jarallax';

/**
 * Page Builder Slider component.
 *
 * This component is part of the Page Builder / PWA integration. It can be consumed without Page Builder.
 * @typedef Slider
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays a Slider which contains slides.
 */
const Slider = props => {
  const classes = mergeClasses(defaultClasses, props.classes);

  const {
    minHeight,
    autoplay,
    autoplaySpeed,
    fade,
    infinite = true,
    showArrows = true,
    showDots = true,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    cssClasses = [],
    children,
    disableLazy,
    isCategory,
  } = props;

  const dynamicStyles = {
    minHeight,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };
  // const jarallaxInstances = {};

  /** @type {import('react-slick').Settings} */
  const sliderSettings = {
    dots: showDots,
    arrows: showArrows,
    // afterChange: () => {
    //   Object.keys(jarallaxInstances).map(key => {
    //     jarallax(jarallaxInstances[key].element, 'onScroll');
    //   });
    // },
    infinite,
    autoplay,
    autoplaySpeed,
    fade,
    className: 'center',
    centerPadding: '0px',
    speed: 700,
    margin: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Override classes on banner to ensure min height is respected
  // Children.map(children, (child, index) => {
  //   if (child.props && child.props.data) {
  //     child.props.data.classes = {
  //       root: classes.bannerRoot,
  //       link: classes.bannerLink,
  //       wrapper: classes.bannerWrapper,
  //       posterOverlay: classes.bannerPosterOverlay
  //     };
  // child.props.data.getParallax = (element, options) => {
  //   jarallaxInstances[index] = {
  //     element,
  //     options
  //   };
  // };
  //   }
  //   return child;
  // });

  return (
    <div className={['products-slider', ...cssClasses].join(' ')} style={dynamicStyles}>
      <SlickSlider {...sliderSettings}>
        {isCategory
          ? children
          : Children.map(children, child =>
              child.props.children.children.map(item => <Slide data={item} disableLazy={disableLazy} />),
            )}
      </SlickSlider>
    </div>
  );
};

/**
 * Props for {@link Slider}
 * @typedef props
 *
 * @property {object} classes An object containing the class names for the Slider
 * @property {string} classes.root CSS class for the slider root element
 * @property {string} classes.bannerRoot CSS class for the child banner item
 * @property {string} classes.bannerLink CSS class for the child banner item
 * @property {string} classes.bannerWrapper CSS class for the child banner item
 * @property {string} classes.bannerPosterOverlay CSS class for the child banner item
 * @property {string} minHeight CSS minimum height property
 * @property {boolean} autoplay Whether the slider should autoplay
 * @property {string} autoplaySpeed The speed at which the autoplay should move the slide on
 * @property {boolean} fade Fade between slides
 * @property {boolean} infinite Whether to infinitely scroll the slider
 * @property {boolean} showArrows Whether to show arrows on the slide for navigation
 * @property {boolean} showDots Whether to show navigation dots at the bottom of the slider
 * @property {string} textAlign Alignment of content within the slider
 * @property {string} border CSS border property
 * @property {string} borderColor CSS border color property
 * @property {string} borderWidth CSS border width property
 * @property {string} borderRadius CSS border radius property
 * @property {string} marginTop CSS margin top property
 * @property {string} marginRight CSS margin right property
 * @property {string} marginBottom CSS margin bottom property
 * @property {string} marginLeft CSS margin left property
 * @property {string} paddingTop CSS padding top property
 * @property {string} paddingRight CSS padding right property
 * @property {string} paddingBottom CSS padding bottom property
 * @property {string} paddingLeft CSS padding left property
 * @property {string[]} cssClasses List of CSS classes to be applied to the component
 */
Slider.propTypes = {
  classes: shape({
    root: string,
    bannerRoot: string,
    bannerLink: string,
    bannerWrapper: string,
    bannerPosterOverlay: string,
  }),
  appearance: oneOf(['default']),
  minHeight: string,
  autoplay: bool,
  autoplaySpeed: number,
  fade: bool,
  infinite: bool,
  showArrows: bool,
  showDots: bool,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string),
};

export default Slider;
