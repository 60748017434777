import React, {useState} from 'react';
import {useQuery} from 'react-apollo';
import {GET_PRODUCT_SLIDER} from '../../queries/misc';
import SlickSlider from 'react-slick';
import ProductItem from '../../components/Category/ProductItem/ProductItem';
import SliderLoadingPlaceholder from './SliderLoadingPlaceholder';
import './ProductSlider.scss';
import {normalizeProductItems} from "../../components/Category/Products/Products";

const sliderSettings = {
  dots: false,
  arrows: true,
  // afterChange: () => {
  //   Object.keys(jarallaxInstances).map(key => {
  //     jarallax(jarallaxInstances[key].element, 'onScroll');
  //   });
  // },
  infinite: true,
  // autoplay: 1,
  // autoplaySpeed: 0.2,
  className: 'center',
  centerPadding: '1px',
  speed: 700,
  margin: 0,
  adaptiveHeight: true,
  slidesToShow: 5, // n items
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1225,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 918,
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
//TODO: fetch from config
const hardHtml = '.html';

const NewProductSlider = ({ conditionsEncoded, html, ...props }) => {
  if (!html) {
    return <></>;
  }
  if (!conditionsEncoded) {
    return <></>;
  }

  function extractItems(text) {
    const regex = /(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g;
    return text.match(regex);
  }

  const extHtml = extractItems(html);
  const isCount = extHtml.find(x => x.includes('products_count'));
  const productCount = isCount ? isCount.match(/\d+/)[0] : null;

  const [products, setProducts] = useState([]);

  const {
    minHeight,
    autoplay,
    autoplaySpeed,
    fade,
    infinite,
    showArrows,
    showDots,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    cssClasses = [],
  } = props;

  const dynamicStyles = {
    minHeight,
    autoplay,
    autoplaySpeed,
    fade,
    infinite,
    showArrows,
    showDots,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };



  const optionalCategory = (props) => {
    if(props && props.conditionOption === "category_ids" && props.categoryIds) {
      const numberPattern = /\d+/g;
      const matches = props.categoryIds.match( numberPattern )
      if(matches.length){
        return Number.parseInt(matches[0]);
      }
    }
    return undefined;
  }

  const { data, loading } = useQuery(GET_PRODUCT_SLIDER, {
    variables: {
      sort: props.sortOrder || undefined,
      category: optionalCategory(props),
      condition: conditionsEncoded,
      pageSize: Number(productCount),
    },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      try {
        setProducts(normalizeProductItems(data.productsByCondition.items))
      } catch (error) {
      }
    }
  });

  if (loading) {
    return <SliderLoadingPlaceholder />;
  }

  return (
    <ul>
      <SlickSlider
        style={{ ...dynamicStyles }}
        {...sliderSettings}
        {...{slidesToShow: Math.min(products.length, 4)}}
        {...{slidesToScroll: Math.min(products.length, 4)}}
        className="product-slider"
      >
        {products.map(item => (
          <ProductItem key={`${item.sku}-${Math.random()}`} product={item} isCms />
        ))}
      </SlickSlider>
    </ul>
  );
};

export default NewProductSlider;
