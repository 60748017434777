import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import parse from 'html-react-parser';
import { GET_CMS_BLOCKS } from '../../commonQueries';
import './Block.scss';

const Block = props => {
  let { html, block_id } = props;
  if(!block_id) {
    // Regex for finding block id
    const regex = /block_id="(.*?)"/;
    const id = html.match(regex);
    block_id=id[1];
  }
  const { data } = useQuery(GET_CMS_BLOCKS, {
    variables: { identifiers: block_id || '' },
  });
  const pageContent =
    data &&
    data.cmsBlocks.items[0].hydrated_content &&
    data.cmsBlocks.items[0].hydrated_content.replace(/#html-body/g, 'body');

  return <div>{pageContent && parse(pageContent)}</div>;
}

export default Block;