import React, {memo}  from 'react'

// TODO: better structure / styling for this / make it configurable
const SliderLoadingPlaceholder = () => (
    <div className="cms-page-view">
        <div className="slick-slide">
            <div className="page-main">
                <div className="slick-slide">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
)

export default memo(SliderLoadingPlaceholder)
