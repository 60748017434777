import Helmet from "react-helmet";
import React from "react";
import {BuildAbsoluteUrl} from "../utils/urlBuilder";

const SeoBlock = ({routeData}) => {

    const {canonical_url, meta_title, meta_keywords, meta_description, url_key} = routeData;
    let baseImage='https://s3.emmezeta.hr/media/emmezeta.png';
    const canonicalUrl = BuildAbsoluteUrl(canonical_url ? canonical_url : url_key);

    switch (routeData.__typename) {
        case 'CategoryTree':
            const storePath = routeData.current_store_paths && routeData.current_store_paths[0];
            const baseUrlImage = storePath && storePath.base_url_image;
            if (baseUrlImage) {
                baseImage = baseUrlImage
            }
            break;
        case 'SimpleProduct':
            case 'GroupedProduct':
                const {
                    gallery
                } = routeData || {};

                const firstImage = gallery && gallery.length && gallery.reduce((prev, curr) => (prev.position < curr.position ? prev : curr));
                const image =
                    firstImage && (firstImage.large_image_url || firstImage.medium_image_url || firstImage.small_imgage_url);
                if (image) {
                    baseImage = image;
                }
                break;
            case 'CmsPage':
                break;
        }
    return (
        <Helmet>
            {meta_title && <title>{meta_title}</title>}
            {meta_title && <meta property="og:title" content={meta_title}/>}
            {meta_keywords && <meta property="og:keywords" content={meta_keywords}/>}
            {meta_description && <meta property="og:description" content={meta_description}/>}
            {canonicalUrl && <meta property="og:url" content={canonicalUrl}/>}
            {baseImage && <meta property="og:image" content={baseImage}/>}

            {meta_description && <meta name="description" content={meta_description}/>}
            {meta_keywords && <meta name="keywords" content={meta_keywords}/>}

            {canonicalUrl && <link rel="canonical" href={canonicalUrl}/>}
        </Helmet>
    )
}

export default SeoBlock