import React from 'react';
import clsx from 'clsx';
import { arrayOf, bool, oneOf, shape, string } from 'prop-types';
import { verticalAlignmentToFlex } from '../../utils/stylesDictionary';
import { resourceUrl } from '../../utils/resourceUrl';

const TabItem = props => {
  const {
    minHeight,
    verticalAlignment,
    backgroundColor,
    desktopImage,
    mobileImage,
    backgroundSize,
    backgroundPosition,
    backgroundAttachment,
    backgroundRepeat,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    children,
    cssClasses = [],
  } = props;

  let image = desktopImage;
  if (mobileImage && typeof window.matchMedia === 'function' && window.matchMedia('(max-width: 768px)').matches) {
    image = mobileImage;
  }
  const dynamicStyles = {
    minHeight,
    verticalAlignment,
    backgroundColor,
    textAlign,
    border,
    borderColor,
    borderWidth,
    borderRadius,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  };

  if (image) {
    const resourceImage = resourceUrl(image, {
      type: 'image-wysiwyg',
      quality: 85,
    });
    dynamicStyles.backgroundImage = `url(${resourceImage})`;
    dynamicStyles.backgroundSize = backgroundSize;
    dynamicStyles.backgroundPosition = backgroundPosition;
    dynamicStyles.backgroundAttachment = backgroundAttachment;
    dynamicStyles.backgroundRepeat = backgroundRepeat ? 'repeat' : 'no-repeat';
  }

  if (verticalAlignment) {
    dynamicStyles.display = 'flex';
    dynamicStyles.justifyContent = verticalAlignmentToFlex(verticalAlignment);
    dynamicStyles.flexDirection = 'column';
  }

  return (
    <div style={dynamicStyles} className={clsx(cssClasses, 'tabs-item')}>
      {children}
    </div>
  );
};

/**
 * Props for {@link TabItem}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the TabItem
 * @property {String} verticalAlignment Vertical alignment of content within tab item
 * @property {String} minHeight CSS minimum height property
 * @property {String} backgroundColor CSS background-color property
 * @property {String} desktopImage Background image URL to be displayed on desktop devices
 * @property {String} mobileImage Background image URL to be displayed on mobile devices
 * @property {String} backgroundSize CSS background-size property
 * @property {String} backgroundPosition CSS background-position property
 * @property {String} backgroundAttachment CSS background-attachment property
 * @property {Boolean} backgroundRepeat CSS background-repeat property
 * @property {String} textAlign Alignment of content within the tab item
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 */
TabItem.propTypes = {
  verticalAlignment: oneOf(['top', 'middle', 'bottom']),
  minHeight: string,
  backgroundColor: string,
  desktopImage: string,
  mobileImage: string,
  backgroundSize: string,
  backgroundPosition: string,
  backgroundAttachment: string,
  backgroundRepeat: bool,
  textAlign: string,
  border: string,
  borderColor: string,
  borderWidth: string,
  borderRadius: string,
  marginTop: string,
  marginRight: string,
  marginBottom: string,
  marginLeft: string,
  paddingTop: string,
  paddingRight: string,
  paddingBottom: string,
  paddingLeft: string,
  cssClasses: arrayOf(string),
};

export default TabItem;
