import React from 'react';
import { bool, oneOf, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

const extractPathname = customLink => {
  const url = new URL(customLink);
  // To support search
  if (url.search.length) {
    return `${url.pathname}${url.search}`;
  }
  return url.pathname;
};

const LinkUrl = ({ linkUrl, children, canonical_url, ...rest }) => {
  try {
    if (linkUrl === undefined) {
      return <></>;
    }

    if (linkUrl.default) {
      if (linkUrl.setting) {
        return (
          <a href={linkUrl.default} {...rest}>
            {children}
          </a>
        );
      }
      return (
        <Link to={extractPathname(linkUrl.default)} {...rest}>
          {children}
        </Link>
      );
    }

    // If we have a prettified version of the link, return it
    if (canonical_url) {
      return (
        <Link to={extractPathname(canonical_url)} {...rest}>
          {children}
        </Link>
      );
    }

    if (linkUrl.category) {
      return (
        <Link to={`/catalog/category/view/id/${linkUrl.category}`} {...rest}>
          {children}
        </Link>
      );
    }

    if (linkUrl.page) {
      return (
        <Link to={`/cms/page/view/page_id/${linkUrl.page}`} {...rest}>
          {children}
        </Link>
      );
    }
  } catch (e) {
    console.warn('Failed to resolve link', linkUrl, children, e);
  }
  return (
    <a href="#" {...rest}>
      {children}
    </a>
  );
};

export default LinkUrl;

LinkUrl.propTypes = {
  linkUrl: shape({
    default: string,
    setting: bool,
    type: oneOf(['default', 'category', 'product', 'page']),
  }),
};
