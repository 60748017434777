import React from 'react';
import Row from '../Row/Row';
import ColumnGroup from '../ColumnGroup/ColumnGroup';
import Column from '../Column/Column';
import Image from '../Image/Image';
import Text from '../Text/Text';
import Slider from '../Slider/Slider';
import Html from '../Html/Html';
import NewProductSlider from '../ProductsSlider/NewProductSlider';
import ButtonItem from '../ButtonItem/ButtonItem';
import Divider from '../Divider/Divider';
import Block from '../Block/Block';
import Tabs from '../Tabs/Tabs';
import TabItem from '../TabItem/TabItem';
import loadable from "@loadable/component";
const QuarticonProductSlider = loadable(() => import('../ProductsSlider/QuarticonProductSlider'), { ssr: false });
const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const normalizeProps = props => {
  const newProps = {};
  Object.entries(props).map(([key, value]) => {
    switch (key) {
      case 'display':
        return (newProps[toCamel(key)] = value ? 'flex' : 'none');
      case 'enable_parallax':
        return (newProps[toCamel(key)] = Boolean(Number(value)));
      case 'parallax_speed':
        return (newProps[toCamel(key)] = Number(value));
      case 'css_classes':
        return (newProps[toCamel(key)] = value.split(' '));
      case 'margins_and_padding':
        if(value.margin){
          Object.entries(value.margin).map(([key, value]) => {
            newProps[toCamel(`margin_${key}`)] = `${value}px`;
          });
        }
        if(value.padding){
          Object.entries(value.padding).map(([key, value]) => {
            newProps[toCamel(`padding_${key}`)] = `${value}px`;
          });
        }
        return;
      case 'image':
        return (newProps['desktopImage'] = value[0]);
      case 'mobile_image':
        return (newProps[toCamel(key)] = value[0]);
      case 'autoplay_speed':
        return (newProps[toCamel(key)] = Number(value));
      case 'block_id':
        return (newProps[key] = value);
      case 'fade':
      case 'autoplay':
      case 'show_arrows':
      case 'show_dots':
        return (newProps[toCamel(key)] = value === 'true');
      default:
        return (newProps[toCamel(key)] = Number(value) ? `${value}px` : value);
    }
  });
  return newProps;
};

const Heading = ({ headingText, headingType }) => {
  const CustomTag = `${headingType}`;
  return <CustomTag>{headingText}</CustomTag>;
};

const Buttons = props => {
  return <div>{props.children}</div>;
};

const WidgetProcessor = props => {
  if (props && props.html && props.html.includes('bestseller')) {
    return <QuarticonProductSlider type="home_bestseller" isHome cnt={4}/>;
  }

  if (props && props.html && props.html.includes('recommended')) {
    return <QuarticonProductSlider type="home_recommended" isHome cnt={4}/>;
  }

  return <Html {...props} />;
};

const componentsTypes = {
  row: Row,
  'column-group': ColumnGroup,
  'button-item': ButtonItem,
  'tab-item': TabItem,
  column: Column,
  image: Image,
  text: Text,
  slider: Slider,
  html: WidgetProcessor,
  heading: Heading,
  divider: Divider,
  buttons: Buttons,
  products: NewProductSlider,
  block: Block,
  tabs: Tabs,
};

export const getComponentByType = type => {
  if (componentsTypes[type]) {
    return componentsTypes[type];
  }
};

// How many elements will have SSR disabled
const disableSSRFor = 3;

const RootComponent = ({ children, disableLazy = false }) => {
  if (!children || !children.children || children.children.length === 0) {
    return null;
  }

  // Rewrite the top-level children so that they don't use lazy-loading
  if (children.name === 'root-container') {
    // children.children = children.children.map((x, i) => (i <= disableSSRFor - 1 ? { ...x, disableLazy: true } : x));
  }

  return (
    children &&
    children.children &&
    children.children.map(child => {
      const Component = getComponentByType(child.name);

      if (!Component) {
        // TODO: logging
        return null;
      }

      if (!child || !child.data || !child.data.display) {
        // TODO: logging
        return null;
      }

      // Todo: fix the passing children as props thing

      return (
        <Component {...normalizeProps(child.data)} key={child.id} disableLazy={disableLazy}>
          <RootComponent children={child} disableLazy={child.disableLazy} />
        </Component>
      );
    })
  );
};

export default React.memo(RootComponent);
